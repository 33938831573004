import { useToken } from '@hub/design-system-base';
import { pickBestContrast } from '../hub-components/feature';
const useColorToken = (color, defaultColor) => {
  return useToken('colors', color || defaultColor, color !== null && color !== void 0 ? color : undefined);
};
export const useSupportingColors = (supportingColor, overrides) => {
  const darkForegroundColorForLightBackgrounds = useColorToken(overrides === null || overrides === void 0 ? void 0 : overrides.darkForegroundColor, 'textPrimary');
  const lightForegroundColorForDarkBackgrounds = useColorToken(overrides === null || overrides === void 0 ? void 0 : overrides.lightForegroundColor, 'textSurfacePrimary');
  const supportingBackgroundColor = useColorToken(supportingColor, 'surfaceBrandPrimary');
  const supportingForegroundColor = pickBestContrast(supportingBackgroundColor, [darkForegroundColorForLightBackgrounds, lightForegroundColorForDarkBackgrounds]);
  return {
    supportingForegroundColor,
    supportingBackgroundColor
  };
};