import React, { ReactElement, useCallback } from 'react'
import {
  ColorCards,
  ColorCard,
} from '@scentregroup/shared/hub-components/color-cards'
import { AsMeasured } from '@scentregroup/shared/hub-components/as-measured'
import { transformContentClickedItemsProperties } from '@scentregroup/shared/analytics-segment/events/content-item'
import { useAnalyticsSimplified as useAnalytics } from '@scentregroup/analytics'
import {
  SGPageTemplateContentCollection,
  SGPageTemplateContentEntry,
} from '@scentregroup/shared/types/page-templates'
import { ModuleProps } from '../../types'
import { TrackBox } from '@scentregroup/shared/analytics-observer'

const AggregateImpressionTracker: React.FC<{
  event?: { eventName?: string | null; eventUrl?: string | null }
  idx: number
  handleImpression: (url: string) => void
  aggregate: React.JSX.Element
}> = ({ event, aggregate, idx, handleImpression }) => {
  const { eventName, eventUrl } = event ?? {}
  const onCreateImpressionWhenView = useCallback(() => {
    if (eventUrl) {
      handleImpression(eventUrl)
    }
  }, [handleImpression, eventUrl])

  if (!eventName || !eventUrl) {
    return aggregate
  }

  return (
    <TrackBox
      key={`${idx}-${eventName}`}
      onCreateImpressionWhenView={onCreateImpressionWhenView}
    >
      {React.cloneElement(aggregate, {
        ...aggregate.props,
        style: { display: 'grid' },
      })}
    </TrackBox>
  )
}

export default function SGPageTemplateContentCollectionColourCardsModule({
  module,
  enableH1Heading: enableH1Heading,
}: ModuleProps<SGPageTemplateContentCollection>): JSX.Element | null {
  const { trackEvent } = useAnalytics()
  const items = React.useMemo(
    () =>
      module.items.filter(
        (item): item is SGPageTemplateContentEntry =>
          item.__typename === 'SGPageTemplateContentEntry'
      ),
    [module.items]
  )
  const ColorCardsAs = React.useMemo(() => {
    return (asProps: Parameters<typeof AsMeasured>[0]) => (
      <AsMeasured
        {...asProps}
        moduleKind={module.kind}
        moduleMetadata={module.analyticsMetadata}
      />
    )
  }, [module.analyticsMetadata, module.kind])

  const AnalyticsImpressionTrackerAs = React.useMemo(() => {
    return items.map(item => (props: Parameters<typeof AsMeasured>[0]) => (
      <AsMeasured
        {...props}
        metadata={item.analyticsMetadata}
        slot={module.analyticsMetadata?.displayId}
      />
    ))
  }, [items, module.analyticsMetadata?.displayId])

  if (items.length === 0) {
    return null
  }

  const TAG_PROMOTED = 'Promoted'

  const handleImpression = (impressionUrl: string): void => {
    fetch(impressionUrl)
  }
  const triggerOnClick = (index: number): void => {
    trackEvent({
      object: 'Content',
      action: 'Clicked',
      properties: transformContentClickedItemsProperties(
        { ...module, items },
        index
      ),
    })
  }

  return (
    <ColorCards
      as={ColorCardsAs}
      title={module.title ?? undefined}
      enableH1Heading={enableH1Heading}
    >
      {items.map((item, idx): ReactElement | undefined =>
        (item.adConfig?.impressionEvents || []).reduce(
          (aggregate, current): ReactElement => (
            <AggregateImpressionTracker
              event={current ?? undefined}
              handleImpression={handleImpression}
              idx={idx}
              aggregate={aggregate}
            />
          ),
          <TrackBox key={idx}>
            <ColorCard
              {...item}
              {...(item?.adConfig ? { tag: TAG_PROMOTED } : {})}
              as={AnalyticsImpressionTrackerAs[idx]}
              clickEvent={triggerOnClick}
            />
          </TrackBox>
        )
      )}
    </ColorCards>
  )
}
