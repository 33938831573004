import {
  SGPageTemplate,
  SGPageTemplateContentEntry,
  SGPageTemplateModule,
  SGPageTemplateModuleWithAdZones,
  SGPageTemplateWithAdZones,
} from '@scentregroup/shared/types/page-templates'

export function replaceAdZonesInTemplate(
  template: SGPageTemplateWithAdZones,
  contentEntriesByAdZoneId: Map<string, SGPageTemplateContentEntry | null>
): SGPageTemplate {
  return {
    ...template,
    modules: template.modules.map(module =>
      replaceAdZonesInTemplateModule(module, contentEntriesByAdZoneId)
    ),
  }
}

export function replaceAdZonesInTemplateModule(
  module: SGPageTemplateModuleWithAdZones,
  contentEntriesByAdZoneId: Map<string, SGPageTemplateContentEntry | null>
): SGPageTemplateModule {
  switch (module.__typename) {
    case 'SGPageTemplateBasicContent':
    case 'SGPageTemplateDynamicContent':
      return module
    case 'SGPageTemplateContentCollection':
      return {
        ...module,
        items: module.items.flatMap(item => {
          switch (item.__typename) {
            case 'SGPageTemplateAdZone':
              return contentEntriesByAdZoneId.get(item.id) || []
            case 'SGPageTemplateContentEntry':
              return item
            default: {
              const _exhaustive: never = item
              return item
            }
          }
        }),
      }
    default: {
      const _exhaustive: never = module
      return module
    }
  }
}
