import analyticsUtils from '../../helpers/analytics-utils';
import trackClickEvent from '../../helpers/analytics-utils/track-event';
export function trackDeckCTAClick(ctaLabel) {
  trackClickEvent(analyticsUtils.categories.WHATS_HAPPENING_DECK, analyticsUtils.actions.CTA, ctaLabel);
}
export function trackDeckDateRangeClick(rangeLabel) {
  trackClickEvent(analyticsUtils.categories.WHATS_HAPPENING_DECK, analyticsUtils.actions.DATE_SELECT, rangeLabel);
}
export function trackPromotionClickEvent(item) {
  analyticsUtils.DEPRECATED_gaTagTracking({
    event: analyticsUtils.events.PROMOTION_CLICK,
    ecommerce: {
      promoClick: {
        promotions: {
          id: item.id,
          name: item.title,
          position: analyticsUtils.positions.WHATS_HAPPENING_CENTRE,
          dimension90: analyticsUtils.dimensions.DIMENSION90,
          creative: item.body
        }
      }
    }
  });
}