import React from 'react'
import { CategorySpotlight } from '@scentregroup/shared/hub-components/category-spotlight'
import { AsMeasured } from '@scentregroup/shared/hub-components/as-measured'
import { useAnalyticsSimplified as useAnalytics } from '@scentregroup/analytics'
import { transformContentClickedItemsProperties } from '@scentregroup/shared/analytics-segment/events/content-item'
import { SGPageTemplateContentCollection } from '@scentregroup/shared/types/page-templates'
import { ModuleProps } from '../../types'

export default function SGPageTemplateContentCollectionCategoryGridModule({
  module,
  enableH1Heading: enableH1Heading,
}: ModuleProps<SGPageTemplateContentCollection>): JSX.Element | null {
  const { trackEvent } = useAnalytics()

  if (module.items.length === 0) {
    return null
  }

  const triggerOnClick = (index: number): void => {
    trackEvent({
      object: 'Content',
      action: 'Clicked',
      properties: transformContentClickedItemsProperties(module, index),
    })
  }

  return (
    <CategorySpotlight
      title={module.title}
      text={module.shortDescription}
      items={module.items}
      analyticsSlot={module.analyticsMetadata?.displayId}
      enableH1Heading={enableH1Heading}
      as={asProps => (
        <AsMeasured
          {...asProps}
          moduleKind={module.kind}
          moduleMetadata={module.analyticsMetadata}
        />
      )}
      clickEvent={triggerOnClick}
    />
  )
}
