import React, { useContext } from 'react'
import { Deck, DeckCard } from '@scentregroup/shared/components/deck-v2'
import {
  CentreContext,
  PromotionPositionContext,
} from '@scentregroup/shared/context'
import { MarketplaceDeckContainer } from '@scentregroup/shared/components/marketplace-deck-container'
import { transformContentClickedItemsProperties } from '@scentregroup/shared/analytics-segment/events/content-item'
import { useAnalyticsSimplified as useAnalytics } from '@scentregroup/analytics'
import {
  SGPageTemplateContentAnalyticsMetadata,
  SGPageTemplateContentCollection,
  SGPageTemplateContentEntry,
  SGPageTemplateDisplayAnalyticsMetadata,
} from '@scentregroup/shared/types/page-templates'
import { ModuleProps } from '../../types'
import { TrackBox } from '@scentregroup/shared/analytics-observer'

const convertItemToDeckEntryProps = (
  item: SGPageTemplateContentEntry,
  index: number
): DeckCardProps => ({
  analyticsMetadata: item.analyticsMetadata,
  image: item.image.url,
  link: item.callToAction?.url,
  body: item.title,
  index,
})

export interface DeckCardProps {
  analyticsMetadata?: SGPageTemplateContentAnalyticsMetadata
  image: string
  body: string
  link: string
  index: number
}

interface DeckProps {
  analyticsMetadata?: SGPageTemplateDisplayAnalyticsMetadata
  callToActionLabel?: string
  callToActionAddress?: string
  title?: string
  centre: CentreDetails
}

interface CentreDetails {
  timeZone: string
  status: string
  slug: string
}

export default function SGPageTemplateContentCollectionWhatsHappeningDeckModule({
  module,
}: ModuleProps<SGPageTemplateContentCollection>): JSX.Element | null {
  const { trackEvent } = useAnalytics()

  const centre = useContext(CentreContext)
  if (!centre) {
    return null
  }
  const moduleV2ToV1: DeckProps = {
    analyticsMetadata: module.analyticsMetadata,
    callToActionLabel: module.callToAction?.label,
    callToActionAddress: module.callToAction?.url,
    title: module.title || '',
    centre,
  }
  const triggerOnClick = (index: number): void => {
    trackEvent({
      object: 'Content',
      action: 'Clicked',
      properties: transformContentClickedItemsProperties(module, index),
    })
  }

  return (
    <PromotionPositionContext.Provider
      value={moduleV2ToV1?.analyticsMetadata?.displayId}
    >
      <MarketplaceDeckContainer>
        <Deck {...moduleV2ToV1}>
          {(module.items || []).map((item, index) => {
            const entryProps = convertItemToDeckEntryProps(item, index)
            return (
              <TrackBox key={entryProps.body}>
                <DeckCard {...entryProps} clickEvent={triggerOnClick} />
              </TrackBox>
            )
          })}
        </Deck>
      </MarketplaceDeckContainer>
    </PromotionPositionContext.Provider>
  )
}
