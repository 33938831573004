function getHostAndProtocol() {
  const location = window.location;
  return "".concat(location.protocol, "//").concat(location.host);
}
export function transformContentClickedItemsProperties(module, index) {
  var _item$analyticsMetada, _item$analyticsMetada2, _item$orchestrationMe, _item$orchestrationMe2, _module$analyticsMeta, _item$orchestrationMe3;
  const item = module.items[index];
  const url = item.callToAction.url;
  const hostAndProtocol = "".concat(getHostAndProtocol(), "/");
  const content = {
    label: 'content_clicked',
    category: 'Content',
    title: item.title,
    correlation_id: (_item$analyticsMetada = (_item$analyticsMetada2 = item.analyticsMetadata) === null || _item$analyticsMetada2 === void 0 ? void 0 : _item$analyticsMetada2.correlationId) !== null && _item$analyticsMetada !== void 0 ? _item$analyticsMetada : '',
    content_type: item.kind,
    image_url: item.image.url.replace(/^\/\//, 'https://'),
    url: url.replace(/^\//, hostAndProtocol),
    module_slug: (_item$orchestrationMe = (_item$orchestrationMe2 = item.orchestrationMetadata) === null || _item$orchestrationMe2 === void 0 ? void 0 : _item$orchestrationMe2.moduleSlug) !== null && _item$orchestrationMe !== void 0 ? _item$orchestrationMe : ((_module$analyticsMeta = module.analyticsMetadata) === null || _module$analyticsMeta === void 0 ? void 0 : _module$analyticsMeta.position) || '',
    personalisation_method: ((_item$orchestrationMe3 = item.orchestrationMetadata) === null || _item$orchestrationMe3 === void 0 ? void 0 : _item$orchestrationMe3.__typename) === 'ContentOrchestrationAnalyticsMetadataPersonalised' ? item.orchestrationMetadata.methodUsed : 'NO_PERSONALISATION'
  };
  return content;
}