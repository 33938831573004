import { ApolloClient } from '@apollo/client'
import { GetCentreQuickLinksDocument } from '@scentregroup/shared/graphql'
import { SGPageTemplateContentCollection } from '@scentregroup/shared/types/page-templates'
import { parseContentEntry } from '../parsing/content-entry'

export async function getCentreQuickLinks<TCacheShape>(
  apollo: Pick<ApolloClient<TCacheShape>, 'query'>,
  centreSlug: string
): Promise<SGPageTemplateContentCollection> {
  const { data } = await apollo.query({
    context: { version: 2 },
    query: GetCentreQuickLinksDocument,
    variables: {
      centreSlug,
    },
  })

  const items = data.quickLinks.map(parseContentEntry)
  const quickLinks = items.filter(Boolean)
  const displayId = quickLinks[0]?.orchestrationMetadata?.displayId
  const correlationItemIds = quickLinks.map(
    item => item?.analyticsMetadata?.correlationId
  )

  const quickLinksModule = {
    __typename: 'SGPageTemplateContentCollection',
    kind: 'UTILITY_PANEL',
    items: quickLinks,
    analyticsMetadata: {
      displayId,
      correlationIds: correlationItemIds.filter(Boolean),
    },
  } as SGPageTemplateContentCollection

  return quickLinksModule
}
