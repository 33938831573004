import React from 'react'
import { SGPageTemplateModule } from '@scentregroup/shared/types/page-templates'
import { ModuleComponent, ModuleProps } from '../types'
import { default as SGPageTemplateContentCollection } from './content-collection'
import { default as SGPageTemplateDynamicContent } from './dynamic-content'
import { default as SGPageTemplateBasicContent } from './basic-content'

export function Module<M extends SGPageTemplateModule>({
  module,
  enableH1Heading: enableH1Heading,
}: ModuleProps<M>): JSX.Element | null {
  const Component = findModuleComponent(module)
  if (!Component) {
    console.log(`Unsupported module type: ${module.__typename}`)
    return null
  }
  return <Component module={module} enableH1Heading={enableH1Heading} />
}

function findModuleComponent<M extends SGPageTemplateModule>(
  module: M
): ModuleComponent<M> | undefined {
  const type = module.__typename
  if (!type) {
    return undefined
  }
  return moduleComponents[type] as unknown as ModuleComponent<M> // we've got type assurance through the map
}

const moduleComponents: {
  [key in SGPageTemplateModule['__typename']]: ModuleComponent<
    Extract<SGPageTemplateModule, { __typename: key }>
  >
} = {
  SGPageTemplateBasicContent,
  SGPageTemplateContentCollection,
  SGPageTemplateDynamicContent,
}
